<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.event.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.event.back' | translate}}</button>
                </div>
                <div class="col-md-3 offset-md-6">
                    <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                </div>

            </div>

        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.event.new' | translate">
                    <form @submit.prevent="onSubmit" autocomplete="off">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.event.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('type_event')}">
                                                <div class="input-group">
                                                    <multiselect
                                                        v-model="id_type_event"
                                                        deselect-label="Selected Element"
                                                        label="name"
                                                        placeholder="Select an Element"
                                                        :options="TypeEvent"
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        >
                                                        <template slot="singleLabel" slot-scope="{ option }">
                                                          {{'view.event.fields.type_event' | translate}}:
                                                          <label style="font-weight:600">{{ option.name }}</label>
                                                        </template>
                                                    </multiselect>
                                                    <!-- <vuestic-simple-select :required="true" :label="'view.event.fields.type_event' | translate" v-model="id_type_event" :options="TypeEvent" option-key="name" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                                    <small v-show="errors.has('type_event')" class="help text-danger">
                                                        {{ errors.first('type_event') }}
                                                    </small> -->
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('start')}">
                                                <div class="input-group">
                                                    <vuestic-date-picker id="date-picker-time" :config="{enableTime: true}" v-model="start" />
                                                    <label class="control-label" for="date-picker-time">
                                                        {{ $t('view.event.fields.start') }}
                                                    </label>
                                                    <i class="bar"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('end')}">
                                                <div class="input-group">
                                                    <vuestic-date-picker id="date-picker-time" :config="{enableTime: true}" v-model="end" />
                                                    <label class="control-label" for="date-picker-time">
                                                        {{ $t('view.event.fields.end') }}
                                                    </label>
                                                    <i class="bar"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('timezone')}">
                                                <div class="input-group">
                                                    <multiselect
                                                        v-model="timezone"
                                                        deselect-label="Selected Element"
                                                        label="name"
                                                        placeholder="Select an Element"
                                                        :options="timezones"
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        >
                                                        <template slot="singleLabel" slot-scope="{ option }">
                                                          {{'view.event.fields.timezone' | translate}}
                                                          <label style="font-weight:600">{{ option.name }}</label>
                                                        </template>
                                                    </multiselect>
                                                    <!-- <vuestic-simple-select :required="true" :label="'view.event.fields.timezone' | translate" v-model="timezone" :options="timezones" option-key="name" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                                    <small v-show="errors.has('timezone')" class="help text-danger">
                                                        {{ errors.first('timezone') }}
                                                    </small> -->
                                                </div>
                                            </div>
                                        </div>

                                        <!--<div class="col-md-3 with-icon-right">-->
                                            <!--<div class="form-group with-icon-right" :class="{'has-error': errors.has('max_virtual_credit')}">-->
                                                <!--<div class="input-group">-->
                                                    <!--<input id="max_virtual_credit" name="max_virtual_credit" v-model="max_virtual_credit" v-validate="'required|numeric'" />-->
                                                    <!--<i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>-->
                                                    <!--<i class="fa fa-check valid-icon icon-right input-icon"></i>-->
                                                    <!--<label class="control-label" for="max_virtual_credit">{{'view.event.fields.max_virtual_credit' | translate}} </label><i class="bar"></i>-->
                                                    <!--<small v-show="errors.has('max_virtual_credit')" class="help text-danger">-->
                                                        <!--{{ errors.first('max_virtual_credit') }}-->
                                                    <!--</small>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-md-3 with-icon-right">-->
                                            <!--<div class="form-group with-icon-right" :class="{'has-error': errors.has('max_normal_credit')}">-->
                                                <!--<div class="input-group">-->
                                                    <!--<input id="max_normal_credit" name="max_normal_credit" v-model="max_normal_credit" v-validate="'required|numeric'" />-->
                                                    <!--<i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>-->
                                                    <!--<i class="fa fa-check valid-icon icon-right input-icon"></i>-->
                                                    <!--<label class="control-label" for="max_normal_credit">{{'view.event.fields.max_normal_credit' | translate}} </label><i class="bar"></i>-->
                                                    <!--<small v-show="errors.has('max_normal_credit')" class="help text-danger">-->
                                                        <!--{{ errors.first('max_normal_credit') }}-->
                                                    <!--</small>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-md-3 with-icon-right">-->
                                            <!--<div class="form-group with-icon-right" :class="{'has-error': errors.has('currency')}">-->
                                                <!--<div class="input-group">-->
                                                    <!--<vuestic-simple-select :required="true" :label="'view.event.fields.currency' | translate" v-model="currency" :options="currencies" option-key="name" />-->
                                                    <!--<i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>-->
                                                    <!--<i class="fa fa-check valid-icon icon-right input-icon"></i>-->
                                                    <!---->
                                                    <!--<small v-show="errors.has('currency')" class="help text-danger">-->
                                                        <!--{{ errors.first('currency') }}-->
                                                    <!--</small>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                        <div class="col-md-3 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('status')}">
                                                <div class="input-group">
                                                    <vuestic-switch v-model="active">
                                                        <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                                        <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                                    </vuestic-switch>
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                                    <small v-show="errors.has('status')" class="help text-danger">
                                                        {{ errors.first('status') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.event.save')}}</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'new-event',
  components: {
    Multiselect
  },
  data () {
    return {
      name: '',
      start: moment().format('YYYY-MM-DD HH:mm'),
      end: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      timezone: '',
      id_type_event: '',
      max_virtual_credit: '',
      max_normal_credit: '',
      currency: '',
      active: 0,
      TypeEvent: [],
      timezones: [],
      currencies: [],
    }
  },
  mounted () {
    this.loadTypeeventsActive()
      .then((data) => {
        this.TypeEvent = data
      })
      .catch(data => {
        this.error = data.message
      })
    this.loadTimezones()
      .then((data) => {
        this.timezones = data
      })
      .catch(data => {
        this.error = data.message
      })
    this.loadCurrencies()
      .then((data) => {
        this.currencies = data
      })
      .catch(data => {
        this.error = data.message
      })
  },
  methods: {
    ...mapActions([
      'storeEvent',
      'loadTypeeventsActive',
      'loadCurrencies',
      'loadTimezones',
      'addToastMessage',
    ]),
    onSubmit () {
      this.$validator.validate().then(result => {
        if (result) {
          let form = {
            id_company: this.company.id,
            name: this.name,
            id_type_event: this.id_type_event.id,
            start: this.start,
            status: 1,
            end: this.end,
            timezone: this.timezone.id,
            currency: this.currency.iso,
            max_virtual_credit: this.max_virtual_credit,
            max_normal_credit: this.max_normal_credit,
            active: this.active
          }
          this.storeEvent(form)
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })

              this.$router.replace('/event')
            })
            .catch((data) => {
              this.error = data.message
            })
        }
      })
    },
    clear (field) {
      this[field] = ''
    },
    back () {
      this.$router.go(-1)
      // this.$router.push('/')
    },
  },
  computed: {
    ...mapState({
      company: state => state.auth.company,
    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid = this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    },
  },
}
</script>
